<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="sm"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title_form }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="PolicyBusForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Compañia"
                    label-for="PolicyBusForm-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Empresa"
                      rules="required"
                    >
                      <v-select
                        v-model="company_id"
                        label="name"
                        :options="optionsCompany"
                        :loading="loadingCompany"
                        :clearable="true"
                        placeholder="Compañia a la que pertenece el terminal"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                      >
                        <template #no-options="{ }">
                          Sin datos.
                        </template>
                        <template v-slot:option="option">
                          <span :class="option.icon" />
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Título de la política"
                    label-for="PolicyBusForm-title"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Título"
                      vid="Título"
                      rules="required"
                    >
                      <b-form-input
                        id="PolicyBusForm-name"
                        v-model="title"
                        name="PolicyBusForm-name"
                        placeholder="Nombre de la política"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="PolicyBusForm-description"
                  >

                    <validation-provider
                      #default="{ errors }"
                      name="Descripción"
                      vid="Descripción"
                      rules="required"
                    >
                      <b-form-textarea
                        id="PolicyBusForm-description"
                        v-model="description"
                        placeholder="Descripción de la política"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormTextarea,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Policy from '@/libs/models/Policy'
import Company from '@/libs/models/Company'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    'vue-errors': AlertErrors,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // Data
      title: null,
      description: null,
      company_id: null,

      id: null,
      value: [],
      notifmsg: null,
      isNotifmsg: false,

      optionsCompany: [],
      loading: false,
      loadingCompany: false,
      // validation rules
      required,
    }
  },
  computed: {
    title_form() {
      return Object.keys(this.infoData).length > 0 ? 'Editar Terminales' : 'Crear Terminales'
    },
  },
  created() {
    if (this.$can('read', 'company')) {
      this.company_id = JSON.parse(localStorage.getItem('userData')).company
    }
    if (Object.keys(this.infoData).length > 0) {
      this.id = this.infoData.id
      this.title = this.infoData.title
      this.description = this.infoData.description
      this.company_id = this.infoData.company
    }
  },
  async mounted() {
    this.getCompanies()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getCompanies() {
      this.loadingCompany = true
      const { data } = await Company.get()
      this.optionsCompany = data
      this.loadingCompany = false
    },
    save() {
      this.$refs.PolicyBusForm.validate().then(async success => {
        if (success) {
          this.isNotifmsg = false
          if (Object.keys(this.infoData).length > 0) {
            const model = await Policy.find(this.id)
            model.title = this.title
            model.description = this.description
            model.company_id = this.company_id ? this.company_id.id : null
            model.save().then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
            }).catch(error => {
              this.isNotifmsg = true
              this.notifmsg = error.response.data.errors
            })
          } else {
            const model = new Policy({
              title: this.title,
              description: this.description,
              company_id: this.company_id ? this.company_id.id : null,
            })
            model.save().then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
            }).catch(error => {
              this.isNotifmsg = true
              this.notifmsg = error.response.data.errors
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
