import Status from '@/libs/models/Status'

const filterOptions = async () => {
  try {
    const response = await Status.whereIn('id', [1, 2, 3, 4]).get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

async function columnsValue() {
  const columns = [
    {
      label: 'Estado',
      field: 'status.id',
      tdClass: 'text-center',
      width: '220px',
      sortable: false,
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona un estado',
        filterDropdownItems: await filterOptions(),
      },
    },
    {
      label: 'Nombre',
      field: 'name',
      firstSortType: 'asc',
      type: 'photo',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Descripción',
      field: 'description',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },

    {
      label: 'Datos de integración',
      field: 'data_of_payment',
      type: 'text_json',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
  ]
  return columns
}

export { columnsValue, filterOptions }
