import Status from '@/libs/models/Status'

const filterOptionsStatus = async () => {
  try {
    const response = await Status.whereIn('id', [1, 2, 3, 4]).get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

async function columnsValue() {
  const columns = [
    {
      label: 'Estado',
      field: 'status.id',
      tdClass: 'text-center',
      width: '220px',
      sortable: false,
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona un estado',
        filterDropdownItems: await filterOptionsStatus(),
      },
    },
    {
      label: 'Nombre',
      field: 'name',
      firstSortType: 'asc',
      type: 'photo',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Descripción',
      field: 'description',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Url Soap',
      field: 'soap_url',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Key',
      field: 'key',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Consumer Id',
      field: 'consumer_id',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Pago Id',
      field: 'OpcionPagoID',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Medio de pago Id',
      field: 'MedioPagoID',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
  ]
  return columns
}

export { columnsValue, filterOptionsStatus }
